<template>
	<div class="container">
		<div class="videonews">
			<div class="listvideo">

				<blue-title>城市土地展</blue-title>

				<div class="listshipin" @mouseenter.prevent="onmouseenter()" @mouseleave.prevent="mouseleave()">
					<video id="listVideo" class="video-js">
						<source :src="video.file_url" type="video/mp4" />
					</video>
					<div :class="{ 'zhaiyao': true, 'summary': summaryHide }">
						<div class="text">
							{{ video.summary }}
						</div>
					</div>
				</div>
				<div class="biaoti">
					{{ video.title }}
				</div>
				<div class="time">
					<span style="color:#A0A0A0;font-size:12px"  >发布时间：</span><span style="color:#A0A0A0;font-size:12px" >{{ video.created_at }}</span>
				</div>
			</div>
			<div class="news">
				<blue-title>展会新闻</blue-title>
				<div class="zhibolist">
					<div class="tuwen" @click="toDetail(item.id)"  v-for="item in news" :key="item.id">
						<div class="tu">
							<img class="pic" :src="item.cover_url" alt="">
						</div>
						<div class="wen">
							<div class="titlebox">
								<div class="title">
									{{ item.title }}
								</div>
								<div class="showdate">{{ item.times }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="licheng">
			<licheng name="展会历程" :licheng="licheng" @changeYear="changeYear"></licheng>
		</div>
		<div class="citypicshangwu">
			<div class="citypic" :style="{ backgroundImage: 'url(' + join_city_pic_url + ')', backgroundSize: 'contain' }">
				<div calss="positiontitle">
					<blue-title>参展城市</blue-title>
				</div>
			</div>
			<div class="shangwu">
				<hezuo :hezuo="cooperation"></hezuo>
			</div>
		</div>


	</div>
</template>
<script>
import BlueTitle from '@/components/website/BlueTitle'
import { tudizhanDetail, tudizhanLichengDetail } from '@/api/website/zhanting.js'
import Licheng from '@/components/website/Licheng.vue'
import Hezuo from '@/components/website/Hezuo.vue'
export default {
	name: 'PcWebsiteTudizhan',
	components: {
		BlueTitle,
		Licheng,
		Hezuo
	},
	data() {
		return {
			summaryHide: false,
			video: {}, //视频

			news: [], //新闻
			poster: '',
			licheng: {},
			year: [],//历程年
			lichengpic: [],//历程图片
			join_city_pic_url: '',
			cooperation: [],
			listPlayer: null,
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	mounted() {
		this.init()
	},

	methods: {
		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/tudizhan/' + id
			// this.$router.push(tourl)
			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},


		changeYear(year) {
			this.year.map(item => {
				if (item.year === year) {
					item.checked = true
				} else {
					item.checked = false
				}
			})

			//获取当前年
			tudizhanLichengDetail({ year: year }).then(res => {
				this.lichengpic = res.data.list
				this.licheng = {
					year: this.year,
					pics: res.data.list,
					course_year:year,
					title : res.data.title

				}
			})
		},

		init() {
			tudizhanDetail().then(res => {
				this.video = res.data.video
				this.poster = res.data.video.cover_url
				this.licheng = res.data.course
				this.news = res.data.news
				this.year = res.data.course.year
				this.lichengpic = res.data.course.pics
				this.cooperation = res.data.cooperation
				this.join_city_pic_url = res.data.join_city_pic_url

				this.initVideo()
			})

		},

		// 鼠标移入
		onmouseenter() {
			this.summaryHide = true
		},
		// 鼠标移出
		mouseleave() {
			this.summaryHide = false
		},


		initVideo() {
			// this.listPlayer = null

			this.$nextTick((filecover) => {
				//初始化视频方法

				let listPlayer = this.$video(listVideo, {
					//确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
					controls: true,
					//自动播放属性,muted:静音播放
					// autoplay: "muted",
					poster: this.poster,
					autoplay: false,
					//建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
					preload: "auto",
					//视频播放器的显示宽度（以像素为单位）
					width: '790px',
					//视频播放器的显示高度（以像素为单位）
					height: "392px",
				})
				this.listPlayer = listPlayer
			})
		},

	},
};
</script>

<style lang="scss" scoped>
.citypicshangwu {
	height: 795px;
	width: 1200px;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;




	.citypic {
		// background: red;

		width: 830px;
		height: 795px;

		.positiontitle {

			background: #fff;

		}

		.bgimg {
			width: 830px;
			height: 795px;
		}
	}


	.shangwu {
		width: 356px;
		height: 795px;
		background: #FFFFFF;

	}
}


.container {
	width: 1200px;
	margin: 0 auto;
	background: #F0F3F8;
}

.videonews {
	display: flex;
	justify-content: space-between;

	width: 1200px;
	height: 536px;
	display: flex;
	background: '#F0F3F8';
}

.listvideo {

	width: 830px;
	height: 536px;
	background: #ffffff;
	display: flex;
	flex-direction: column;


	.listshipin {
		margin-left: 20px;
		width: 790px;
		height: 392px;

		.zhaiyao {
			// pointer-events:none;
			position: relative;
			top: -54px;
			width: 790px;
			height: 54px;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
			display: flex;
			justify-content: center;
			align-items: center;

			.text {
				width: 760px;
				height: 34px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 17px;


				word-break: break-all;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				/* 这里是超出几行省略 */
				overflow: hidden;

				text-align: left;

			}

		}

		.summary {
			display: none;
		}

	}

	.biaoti {
		margin-left: 20px;
		width: 780px;
		height: 28px;
		font-size: 20px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: bold;
		color: #000000;
		line-height: 28px;
		overflow: hidden;
		white-space: nowrap;
		margin-top: 10px;
		text-overflow: ellipsis;
		cursor: pointer;
		text-align: left;
	}

	.time {
		margin-left: 20px;
		text-align: left;
		width: 100%;
		height: 17px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
	}
}

.news {
	width: 356px;
	height: 536px;
	background: #ffffff;

	.tuwen {
		cursor: pointer;
		height: 70px;
		width: 336px;
		display: flex;
		margin: 0px auto;
		padding: 10px 0px;
		border-bottom: solid 1px #F2F2F2;

		.tu {
			width: 70px;
			height: 70px;
			// overflow: hidden;

			.pic {
				width: 70px;
				height: 70px;
				object-fit: cover;
			}

		}

		.wen {
			width: 256px;
			height: 70px;
			padding-left: 10px;

			// display: flex;
			// flex-direction: column;
			// justify-content: space-between;
			// align-items: flex-start;
			.titlebox {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: flex-start;


				.title {
					display: inline-block;
					height: 46px;
					text-align: left;
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #000000;
					line-height: 23px;

					text-overflow: -o-ellipsis-lastline;
					overflow: hidden; //溢出内容隐藏
					text-overflow: ellipsis; //文本溢出部分用省略号表示
					display: -webkit-box; //特别显示模式
					-webkit-line-clamp: 2; //行数
					line-clamp: 2;
					-webkit-box-orient: vertical; //盒子中内容竖直排列



				}

				.showdate {
					height: 17px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #A0A0A0;
					line-height: 17px;
					text-align: left;
				}
			}


		}
	}

	.tuwen:first-child {
		padding-top: 0;
	}

	.tuwen:last-child {
		border-bottom: none;
	}

}

.licheng {
	width: 1200px;
	height: 497px;
	background: #FFFFFF;
	margin-top: 20px;
}
</style>