import { render, staticRenderFns } from "./tudizhan.vue?vue&type=template&id=72d1851b&scoped=true&"
import script from "./tudizhan.vue?vue&type=script&lang=js&"
export * from "./tudizhan.vue?vue&type=script&lang=js&"
import style0 from "./tudizhan.vue?vue&type=style&index=0&id=72d1851b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d1851b",
  null
  
)

export default component.exports